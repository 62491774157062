import clsx from "clsx";
import { Common, Icon } from "@/components";
import { manifest, optimizeInfo, solution } from "../models";
import { timeFormat } from "@/utils";

interface Props {
  isFirst?: boolean;
  onOpenDetail: () => void;
  solution?: solution;
  manifest: manifest;
  optimizeInfo?: optimizeInfo;
}

export const OptimizedReviewItem = ({
  isFirst,
  onOpenDetail,
  solution,
  manifest,
  optimizeInfo,
}: Props) => {
  return (
    <div
      className={clsx(
        "border border-neutral-20 bg-white px-4 pb-4 pt-3 rounded",
        !isFirst && "mt-3"
      )}
    >
      <div className="border-b border-neutral-20 flex items-center pb-3">
        <div className="flex-1 mr-4">
          <div className="flex items-center mb-2">
            <Common.DotColor
              style={{ backgroundColor: manifest.route.colour }}
            />
            <div className="flex-1 flex items-center">
              <div
                className="font-semibold text-neutral-80 mx-2 line-clamp-1"
                data-tip={manifest.route.name}
              >
                {manifest.route.name}
              </div>
              {/* <Common.Label size="sm" color="green">
                10 mins saved
              </Common.Label> */}
            </div>
          </div>
          <div className="flex items-center">
            <Icon.Driver size="16" className="fill-neutral-40" />
            <div className=" ml-1.5">{manifest.driver.givenName}</div>
            <Icon.Order size="12" className="fill-neutral-40 ml-4 mr-1.5" />
            <div>
              {(solution?.visits && solution?.visits.length) || 0} order
              {solution?.visits && solution?.visits.length > 1 && "s"}
            </div>
          </div>
        </div>
        <Common.ViewDetailBtn onClick={onOpenDetail} />
      </div>
      <div className="mt-3">
        <div className="flex items-center">
          <div className="text-neutral-70">
            <div className="text-xs text-neutral-50 optimize-review-item">
              Start time
            </div>
            {timeFormat(solution?.startTime + ":00")}
          </div>
          <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
          <div className="text-neutral-70">
            <div className="text-xs text-neutral-50">End time</div>
            {timeFormat(solution?.endTime + ":00")}
          </div>

          <div className="text-neutral-70 ml-10">
            <div className="text-xs text-neutral-50">Driver break period</div>
            {timeFormat(optimizeInfo?.breakStartTime)} -{" "}
            {timeFormat(optimizeInfo?.breakEndTime)}
          </div>
        </div>
      </div>
    </div>
  );
};
